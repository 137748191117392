/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2018 Schwartzco Inc.
        License: 1809-PYOYRN     
*/

/*removed italic for now*/

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Super-Web.eot");
  src: url("Graphik-Super-Web.eot?#iefix") format("embedded-opentype"),
    url("Graphik-Super-Web.woff2") format("woff2"),
    url("Graphik-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Super-Web {
  font-family: "Graphik Web";
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Bold-Web.eot");
  src: url("Graphik-Bold-Web.eot?#iefix") format("embedded-opentype"),
    url("Graphik-Bold-Web.woff2") format("woff2"),
    url("Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Bold-Web {
  font-family: "Graphik Web";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Semibold-Web.eot");
  src: url("Graphik-Semibold-Web.eot?#iefix") format("embedded-opentype"),
    url("Graphik-Semibold-Web.woff2") format("woff2"),
    url("Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Semibold-Web {
  font-family: "Graphik Web";
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Medium-Web.eot");
  src: url("Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("Graphik-Medium-Web.woff2") format("woff2"),
    url("Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Medium-Web {
  font-family: "Graphik Web";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Regular-Web.eot");
  src: url("Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"),
    url("Graphik-Regular-Web.woff2") format("woff2"),
    url("Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Regular-Web {
  font-family: "Graphik Web";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Light-Web.eot");
  src: url("Graphik-Light-Web.eot?#iefix") format("embedded-opentype"),
    url("Graphik-Light-Web.woff2") format("woff2"),
    url("Graphik-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Light-Web {
  font-family: "Graphik Web";
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Extralight-Web.eot");
  src: url("Graphik-Extralight-Web.eot?#iefix") format("embedded-opentype"),
    url("Graphik-Extralight-Web.woff2") format("woff2"),
    url("Graphik-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Extralight-Web {
  font-family: "Graphik Web";
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Thin-Web.eot");
  src: url("Graphik-Thin-Web.eot?#iefix") format("embedded-opentype"),
    url("Graphik-Thin-Web.woff2") format("woff2"),
    url("Graphik-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Thin-Web {
  font-family: "Graphik Web";
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}
