@import '../../styles/colors';

.webv2-redirection-container {
  background-color: $white;
  height: 100vh;
  text-align: center;
  overflow: auto;

  .header {
    padding: 22px 0;
  }

  .description {
    height: 252px;
    background-size: cover;
    color: $white;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 33px;

    p {
      margin: 0;
      font-size: 32px;
      line-height: 35px;
      font-weight: 600;
    }
  }

  .instruction {
    font-size: 16px;
    margin: 32px 0;
  }

  .action-buttons {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 10px;

    .goToButton,
    .closeButton {
      border: 0;
      font-size: 16px;
      font-weight: 500;
      border-radius: 30px;
      padding: 18px 80px;
      cursor: pointer;
    }

    .goToButton {
      background-color: $key-feature-color;
      color: $white;
      box-shadow: 0px 3px 6px $text-grey-8;
    }

    .closeButton {
      background-color: $white;
      color: $key-feature-color;
    }
  }
}
