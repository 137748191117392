@import 'src/styles/colors';
@import 'src/styles/constants';
@import '../../node_modules/antd/dist/antd.css';
@import 'fonts/graphik/index.css';

body {
  margin: 0;
  font-family: 'Graphik Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

strong {
  font-weight: 500;
}

.body-container {
  position: fixed;
  top: $header-height;
  bottom: 0;
  left: 0;
  right: 0;
}

.ant-layout,
body {
  background: $bg-color !important;
}

.flex {
  display: flex;
  .flex-auto {
    flex: auto;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.swal2-container.swal2-backdrop-show {
  backdrop-filter: blur(8px) !important;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.swal2-popup {
  background-color: $primary-color !important;
  .swal2-title,
  .swal2-html-container,
  .swal2-footer {
    color: $text-grey-2 !important;
    text-align: center;
    border-color: $text-grey-4;
  }

  .swal2-actions {
    margin-bottom: 8px;
  }

  .swal2-styled {
    padding: 0.5em 1.5em;
  }

  #swal2-title {
    font-size: 28px;
    color: $text-grey-3 !important;
  }

  #swal2-html-container {
    font-size: 16px;
    line-height: 1.5;
    margin: 1em 2em 0;

    @media screen and (max-width: 375px) {
      margin: 1em 1.5em 0;
    }

    #error-message,
    #success-message {
      p {
        text-align: center;

        &:last-child {
          margin: 0;
        }
      }
    }

    #redirect-container {
      padding: 2em;
    }
  }

  #contact-us-link {
    color: $secondary-color;

    &:hover {
      color: $key-feature-color;
    }
  }

  .swal2-styled {
    &.swal2-confirm,
    &.swal2-deny,
    &.swal2-cancel {
      border-radius: 10rem !important;
    }

    &.swal2-confirm {
      background-color: $secondary-color !important;
      color: $primary-color !important;
    }

    &.swal2-deny {
      background-color: $secondary-red !important;
      color: $primary-color !important;
    }
  }

  .swal2-html-container {
    text-align: left !important;
    .value {
      line-height: 1.7;
      font-size: 16px;
      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      strong {
        white-space: nowrap;
      }

      span {
        font-weight: 300;
      }
    }
  }
}

.spunge {
  display: inline-block;
  &:hover {
    color: $primary-color;
    animation: rubberBand 0.7s;
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes scale-display {
  0% {
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
